import 'bootstrap'
import 'iconify-icon'
import '@sonicgarden/autosize-textarea-element'
import '@sonicgarden/bs-hovercard-element'
import '@sonicgarden/auto-submit-form-element'
import '@sonicgarden/bs-tooltip-element'
import '@sonicgarden/leave-check-form-element'
import '@/src/elements/choices-select'
import '@/src/elements/removable-element'
import '@/src/elements/copy-button'
import '@/src/elements/checkbox-all'
import '@/src/elements/shift-checkbox'
import { Turbo } from '@hotwired/turbo-rails'
import { start } from '@sonicgarden/rails-ujs-compat'
import { startFlash } from '@/src/lib/flash'

Turbo.session.drive = false

start()
startFlash()
