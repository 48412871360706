import type { TurboFrameRenderEvent } from '@hotwired/turbo'
import { openSuccess, openError, openSnackbar } from '@/src/lib/toast'
import { scriptJsonData } from './json'

const SCRIPT_JSON_FLASH_ID = 'json-flash'

type FlashData = {
  notice?: string
  alert?: string
}

const isBackForwardNavigation = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return performance.getEntriesByType('navigation').some((entry) => (entry as any).type === 'back_forward')
}

export const startFlash = () => {
  document.addEventListener(
    'DOMContentLoaded',
    () => {
      if (isBackForwardNavigation()) return

      const flashData = scriptJsonData<FlashData>(SCRIPT_JSON_FLASH_ID)
      if (!flashData) return

      if (flashData.notice) {
        openSuccess(flashData.notice)
      }

      if (flashData.alert) {
        openError(flashData.alert)
      }
    },
    { once: true },
  )

  document.addEventListener('turbo:frame-render', (event: Event) => {
    const json = (event as TurboFrameRenderEvent).detail.fetchResponse.header('X-Flash-Messages')
    if (!json) return

    const flashData: FlashData = JSON.parse(json)

    if (flashData.notice) openSnackbar(flashData.notice)
    if (flashData.alert) openError(flashData.alert)
  })
}
