const handleChange = (event: Event): void => {
  const checkbox = event.target as HTMLInputElement
  const { checked } = checkbox
  const form = checkbox.closest('form')
  if (!form) return

  // eslint-disable-next-line unicorn/prefer-spread
  for (const element of Array.from(form.querySelectorAll<HTMLInputElement>('input[type="checkbox"]'))) {
    element.checked = checked
  }
}

export class CheckboxAll extends HTMLElement {
  connectedCallback(): void {
    this.render()
    this.addEventListener('change', handleChange)
  }

  disconnectedCallback(): void {
    this.removeEventListener('change', handleChange)
  }

  render(): void {
    const input = document.createElement('input')
    input.type = 'checkbox'
    // TODO: 外部から指定可能にする
    input.classList.add('form-check-input')
    this.append(input)
  }
}

declare global {
  interface Window {
    CheckboxAll: typeof CheckboxAll
  }
}

if (!window.customElements.get('checkbox-all')) {
  window.CheckboxAll = CheckboxAll
  window.customElements.define('checkbox-all', CheckboxAll)
}
