import type { Choice } from 'choices.js'
import Choices from 'choices.js'

import 'choices.js/public/assets/styles/choices.css'

type State = {
  choices?: Choices
}

const states = new WeakMap<ChoicesSelect, State>()

export class ChoicesSelect extends HTMLElement {
  connectedCallback(): void {
    requestAnimationFrame(() => this.init())
  }

  disconnectedCallback(): void {
    this.state.choices?.destroy()
  }

  init = (): void => {
    if (!this.selectElement) return

    const choices = new Choices(this.selectElement, {
      choices: this.choices,
      renderChoiceLimit: 100,
      searchResultLimit: 10,
      allowHTML: false,
      position: 'bottom',
    })
    this.state = { choices }
  }

  get state(): State {
    return states.get(this) ?? {}
  }

  set state(newState: State) {
    states.set(this, { ...this.state, ...newState })
  }

  get selectElement(): HTMLSelectElement | undefined {
    return this.querySelector('select') ?? undefined
  }

  get choices(): Choice[] {
    const data = this.dataset.choices
    if (!data) return []

    return JSON.parse(data)
  }
}

declare global {
  interface Window {
    ChoicesSelect: typeof ChoicesSelect
  }
}

if (!window.customElements.get('choices-select')) {
  window.ChoicesSelect = ChoicesSelect
  window.customElements.define('choices-select', ChoicesSelect)
}
