type State = {
  lastInput: HTMLInputElement
}
const states = new WeakMap<ShiftCheckbox, State>()

export class ShiftCheckbox extends HTMLElement {
  connectedCallback(): void {
    this.addEventListener('click', this.handleClick)
  }

  disconnectedCallback(): void {
    this.removeEventListener('click', this.handleClick)
  }

  private handleClick = (event: MouseEvent): void => {
    const element = event.target
    if (!(element instanceof HTMLInputElement) || !element.matches('input[type="checkbox"]')) return

    if (!event.shiftKey) {
      states.set(this, { lastInput: element })
      return
    }

    const lastInput = states.get(this)?.lastInput
    if (!lastInput) return

    const checkboxes = [...this.querySelectorAll<HTMLInputElement>('input[type="checkbox"]')]
    const currentIndex = checkboxes.indexOf(element)
    const lastIndex = checkboxes.indexOf(lastInput)

    for (let i = Math.min(currentIndex, lastIndex); i <= Math.max(currentIndex, lastIndex); i++) {
      checkboxes[i].checked = lastInput.checked
    }
  }
}

declare global {
  interface Window {
    ShiftCheckbox: typeof ShiftCheckbox
  }
}

if (!window.customElements.get('shift-checkbox')) {
  window.ShiftCheckbox = ShiftCheckbox
  window.customElements.define('shift-checkbox', ShiftCheckbox)
}
