import { openToast } from '@sonicgarden/bs-toast-element'

export const openSuccess = (message: string): void => {
  openToast(message, { bgColor: 'info', delay: 5000 })
}

export const openError = (message: string): void => {
  openToast(message, { bgColor: 'danger', delay: 20_000 })
}

export const openSnackbar = (message: string): void => {
  openToast(message, { color: 'white', bgColor: 'dark', delay: 1500, position: 'bottom-center' })
}
